// Import Firebase modules
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyATno00rRL2hDpc_BPCJo5B4vIU6pUoTtU",
  authDomain: "nvgen-website.firebaseapp.com",
  projectId: "nvgen-website",
  storageBucket: "nvgen-website.firebasestorage.app",
  messagingSenderId: "44301094010",
  appId: "1:44301094010:web:07ed7e0da59e2103ff3fc3",
  measurementId: "G-LTXSS1C6MK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore, Auth, Storage, and Analytics
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const analytics = getAnalytics(app); // Initialize Firebase Analytics

// Export Firebase services to use in other parts of the app
export { app, db, auth, storage, analytics };
