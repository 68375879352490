import { db, analytics } from "./firebase"; // adjust the import based on your project structure
import { logEvent } from "firebase/analytics";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useState } from "react";
import logo from "./logo.png";
import "./index.css";
import toast, { Toaster } from "react-hot-toast";

export default function ComingSoon() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    logEvent(analytics, "email_subscribed", { key: "value" });

    try {
      // Reference to the 'subscribers' collection in Firestore
      const docRef = await addDoc(collection(db, "subscribers"), {
        email: email,
        timestamp: serverTimestamp(), // Use Firestore's server timestamp
      });
      console.log("Subscribed:", email, docRef.id);

      // Show success toast
      toast.success("Subscribed successfully!", {
        icon: "🎉",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } catch (error) {
      console.error("Error adding email to Firestore:", error);
      toast.error("Error! Please try again.", {
        style: {
          borderRadius: "10px",
          background: "#f56565",
          color: "#fff",
        },
      });
    } finally {
      // Reset loading state and email input
      setLoading(false);
      setEmail("");
    }
  };

  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Toaster position="top-center" reverseOrder={false} />

      <div className="p-4 sm:p-6 flex justify-center sm:justify-start">
        <img src={logo} alt="NVGEN Logo" className="w-10 sm:w-12 md:w-16 h-auto" />
      </div>

      <div className="flex-1 flex items-center justify-center p-4">
        <div className="relative w-[300px] h-[300px] sm:w-[380px] sm:h-[380px] md:w-[480px] md:h-[480px] lg:w-[530px] lg:h-[530px]">
          {/* Adjusted circle background styling with increased opacity and radiation breathing effect */}
          <div className="absolute inset-0 rounded-full border border-gray-700/80 shadow-[0_0_60px_10px_rgba(255,255,255,0.1),0_0_30px_15px_rgba(255,255,255,0.15),0_0_15px_5px_rgba(255,255,255,0.2)] backdrop-blur-sm opacity-95 animate-radiation" />
          <div className="absolute inset-0 bg-gradient-radial from-gray-800/70 via-black to-black rounded-full" />

          <div className="absolute inset-0 flex flex-col items-center justify-center px-4 sm:px-6">
            <h1
              className="sm:text-10xl md:text-7xl tracking-[0.1em] mb-0 sm:mb-2"
              style={{
                fontSize: "clamp(2.4rem, 5vw, 5rem)", // Adjust values as needed
                fontFamily: 'Russo One, sans-serif',
                letterSpacing: "0.1em",
                color: "#B4B4B4", // Change this color as needed
              }}
            >
              NVGEN
            </h1>
            <div className="text-center mb-3 sm:mb-5">
              <p
                className="font-manrope text-xs sm:text-sm md:text-base mb-2 tracking-[0.05em] sm:tracking-[0.1em]"
                style={{
                  fontSize: "clamp(0.7rem, 2vw, 1.3rem)", // Adjust values as needed
                  letterSpacing: "0.1em",
                  color: "#6A6A6A", // Change this color as needed
                }}
              >
                Where Vision & Reality Converge
              </p>
            </div>
            <hr
              className="w-full h-[2px] border-0 mt-3"
              style={{
                background: "linear-gradient(to right, transparent, #6A6A6A, transparent)", // Change gradient color if needed
                height: "2px",
              }}
            />

            <h2
              className="font-centuryGothic text-sm sm:text-base md:text-lg font-light mb-6 sm:mb-10 tracking-[0.1em] mt-7"
              style={{
                fontSize: "clamp(1rem, 2.3vw, 3rem)", // Adjust values as needed
                fontFamily: "Century Gothic, sans-serif",
                letterSpacing: "0.1em",
                color: "#6A6A6A", // Change this color as needed
              }}
            >
              COMING SOON
            </h2>

            <form
              onSubmit={handleSubmit}
              className="w-full max-w-[200px] sm:max-w-[180px] md:max-w-xs space-y-2"
            >
              <input
                type="email"
                placeholder="enter your email for updates"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full p-1.5 sm:p-2 md:p-2.5 rounded text-xs sm:text-xs md:text-sm focus:outline-none focus:ring-1 focus:ring-white/50 border"
                style={{
                  color: "#B4B4B4", // Change input text color
                  backgroundColor: "transparent",
                  placeholder: { color: "#6A6A6A" }, // Change placeholder color
                  borderColor: "#6A6A6A", // Change border color
                  lineHeight: "1.2",
                  textAlign: "center",
                }}
              />
             <div className="flex justify-center">
                <button
                  type="submit"
                  disabled={loading}
                  className="w-1/2 h-[23px] sm:h-[30px] bg-[#1B1B1B] text-[#727272] rounded-[10px] text-xs sm:text-xs md:text-sm font-poppins border border-gray-500 hover:bg-[#2C2C2C] transition-colors ring-1 ring-gray-700 mt-2 flex items-center justify-center mt-1.5"
                >
                  {loading ? (
                    <span className="loader h-3 w-3 border-2 border-t-transparent border-gray-500 rounded-full animate-spin mr-2"></span>
                  ) : (
                    "Subscribe Now!"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <footer className="w-full max-w-[280px] sm:max-w-xs md:max-w-2xl mx-auto text-center p-4 sm:p-6">
        <div className="flex flex-wrap justify-center items-center gap-2 sm:gap-4">
          <a
            href="/privacy"
            className="hover:text-gray-400 transition-colors"
            style={{ color: "#727272" }} // Change footer link color
          >
            Privacy Policy
          </a>
          <span className="hidden sm:inline" style={{ color: "#727272" }}>•</span>
          <a
            href="/terms"
            className="hover:text-gray-400 transition-colors"
            style={{ color: "#727272" }} // Change footer link color
          >
            Terms & Conditions
          </a>
        </div>
      </footer> */}
    </div>
  );
}
